.faq {
  padding: 111px 323px 128px 323px;

  .faq-content {
    .left {
      width: 100%;
      margin-right: 92px;
    }
    .right {
      width: 100%;
    }
  }
}
