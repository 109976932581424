:root {
  --black: rgb(0, 0, 0);
  --light-black: rgb(2, 2, 2);
  --half-white: rgba(255, 255, 255, 0.5);
  --yellow: #ffbf1a;
  --grey: #b1b1b1;
  --white: #ffffff;
  --pink: #f023ab;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Rugen";
  src: url("../assets/font/Rugen.otf");
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: "Poppins";
  src: url();
}