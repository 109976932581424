.modal-Contentanswer {
  margin: 60px;
  background: #7b7b7b;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.53);
  /* background: var(--card, linear-gradient(180deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.07) 100%)); */
  backdrop-filter: blur(51px);
}

.modal-page span {
  color: #fff;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.modal-answer .answer-title {
  display: flex;
  justify-content: center;
}
.modal-answer .answer-title h5 {
  color: #fff;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%; /* 64.8px */
}
.left-question {
  margin-top: 105px;
  margin-left: 50px;
}
.left-question span {
  color: #fff;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.left-question span {
  color: #fff;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.details {
  text-align: center;
  margin-top: 20px;
}
.details span {
  color: #fff;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.answer {
  width: 918px;
  margin-left: 50px;
}
.answer p {
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 36px */
}
.reward-count {
  text-align: center;
  margin-top: 43px;
}
.reward-count p {
  color: #fff;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.reward-count span {
  color: #ffb800;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.next-btn {
  display: flex;
  justify-content: center;
  margin-top: 22px;
}
.next-btn button {
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}
.left-question h4{
  font-size: 42px !important; 
}
.answercontent {
  display: flex;
  width: 100%;
  gap: 10px;
  /* padding-inline: 60px; */
}
.answercontent .answer-left {
  width: 60%;
}
.answercontent .answer-right {
  width: 40%;
}
.mg-part {
  width: 100%;
}
.vedio-css {
  width: 100%;
  border-radius: 7px;
  box-shadow: 0px 0px 48px 9px rgba(0, 0, 0, 0.3);
}
.vedio {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-part {
     max-width: 99%;
    height: 41vh;
    /* margin-right: -9%; */
    margin-top: 22%;
}
.img-fluid{
  max-width: 93%;
    height: 47vh;
    margin-left: 4%;
    margin-top: 7%;
}
/* @media only screen and (max-width: 1200px) {
  .quizModal-section {
    display: flex;
    flex-direction: column;
  }
} */
@media only screen and (max-width: 1730px) {
  .answer {
    width: 600px;
  }
}
@media only screen and (max-width: 1270px) {
  .modal-answer .answer-title h5 {
    font-size: 25px;
  }
  .left-question span {
    font-size: 25px;
  }
  .details span {
    font-size: 25px;
  }
  .answer p {
    font-size: 15px;
  }
  .left-question {
    margin-top: 105px;
    margin-left: 5px;
  }
  .answer {
    width: 450px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 900px) {
  .modal-answer .answer-title h5 {
    font-size: 20px;
  }
  .left-question span {
    font-size: 20px;
  }
  .details span {
    font-size: 20px;
  }
  .answer p {
    font-size: 15px;
  }
  .left-question {
    margin-top: 105px;
    margin-left: 5px;
  }
  .answer {
    width: 400px;
    margin-left: 5px;
  }
  .reward-count p {
    font-size: 19px;
  }
  .reward-count span {
    font-size: 19px;
  }
  .next-btn button {
    font-size: 15px;
  }
}
@media only screen and (max-width: 821px) {
  .answercontent {
    display: flex;
    flex-direction: column;
  }
  .answercontent .answer-left {
    width: 100%;
    order: 2;
  }
  .answer-right {
    width: 100%;
  }
  .answer {
    width: 100%;
  }
  .answercontent .answer-right {
    width: 100%;
  }
  .img-part {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .modal-page span {
    font-size: 18px;
  }
  .modal-answer .answer-title h5 {
    font-size: 13px;
  }
  .left-question {
    margin-top: 10px;
    margin-left: 5px;
  }
  .left-question span {
    font-size: 11px;
  }
  .answer p {
    font-size: 9px;
  }
  .details span {
    font-size: 14px;
  }
  .reward-count p {
    font-size: 11px;
  }
  .reward-count span {
    font-size: 11px;
  }
  .next-btn button {
    padding: 5px 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    color: #000;
    font-family: "Montserrat";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
  }
  .modal-fullscreen .modal-content {
    height: unset;
  }
}
