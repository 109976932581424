.vision {
  // background-color: rgb(202, 137, 137);

  .top {
    background-color: var(--white);
    padding: 51px 100px 56px 100px;
    // h3 {
    //   color: var(--black);
    // }
  }

  .bottom {
    padding: 110px 66px 160px 0px;
    position: relative;

    .right {
      .heading {
        .text-ends {
          display: flex;
          justify-content: end;
        }

        .text-end {
          -webkit-text-stroke: 1px #fffafa;
          color: white;
          text-shadow: 0px 12px 0 #000, 2px 17px 0 #ffffff, -2px 17px 0 #ffffff;
          -webkit-text-stroke-width: 1px;



        }
      }

      .para {
        max-width: 545px;
      }

      .vision-btn {
        .explore {
          width: 110 !important;
          // height: 30px !important;
          padding: 0px 20px !important;
          // height: 18.296px !important;

          color: #000;
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }

    .left {
      // width: 100%;
      // position: absolute;
      // left: -210px;
      // top: 2%;
      // transform: scaleX(-1);
      // max-width: 1258px;
      // object-fit: 810;
      // mix-blend-mode: difference;
      // height: 810px;
      width: 100%;
      position: absolute;
      left: -148px;
      top: 2%;
      transform: scaleX(-1);
      max-width: 1258px;
      object-fit: 810;
      mix-blend-mode: difference;
      height: 810px;
    }
  }

  .bottomLine {
    width: 100%;

    .Bimage {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1700px) {
  .vision {
    .top {
      padding: 70px;

      h3 {
        font-size: 40px;
      }
    }

    .bottom {
      .left {
        left: -160px;
        top: 5%;
        max-width: 860px;
        height: 610px;
      }

      .right {
        h1 {
          font-size: 128.463px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .vision {
    .top {
      padding: 50px;

      h3 {
        font-size: 30px;
      }
    }

    .bottom {
      .left {
        left: -150px;
        top: 1%;
        max-width: 800px;
        height: 550px;
      }

      .right {
        h1 {
          font-size: 70px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .vision {
    .top {
      h3 {
        font-size: 20px;
      }
    }

    .bottom {
      .right {


        h1 {
          font-size: 30px;
        }

        .para {
          max-width: 380px;
        }

        // .vision-btn {
        //   width: 100%;
        // }
      }

      .left {
        left: -90px;
        top: 3%;
        max-width: 500px;
        height: 450px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .vision {
    // .top {

    // }

    .bottom {
      gap: 40px;
      padding: 50px;
      display: flex;
      flex-direction: column-reverse;
      position: unset;
      justify-content: center !important;
      align-items: center !important;

      .left {

        position: unset;
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        video {
          height: 100%;
          max-width: 450px;

        }
      }

      .right {
        display: flex;
        align-items: flex-start;
        max-width: 860px;

        .heading {
          // display: flex;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          width: 100%;

          .text-end {
            font-size: 60px;
          }

          .text-ends {
            font-size: 60px;
            margin-right: 10px;
          }

          .text-end {
            font-size: 60px;
          }



        }

        .para {
          font-size: 16px;
          text-align: center;
          // display: contents;
          // width: 600px !important;
        }

        .vision-btn {
          width: 100%;
          display: flex;
          justify-content: center;
        }


      }
    }
  }
}

@media screen and (max-width: 600px) {
  .vision {
    .top {
      padding: 20px;

      h3 {
        font-size: 13px;
      }
    }

    .bottom {
      gap: 40px;
      padding: 20px;
      display: flex;
      flex-direction: column-reverse;
      position: unset;

      .left {
        position: unset;
        height: 250px;
        width: 50%;

        video {
          width: 300px;
        }

        // .video {
        //   width: 100%;
        // }

      }

      .right {
        display: flex;
        align-items: flex-start;

        .heading {
          // display: flex;
          margin-bottom: 20px;
        }

        .para {
          font-size: 14px;
          // display: contents;
        }

        h1 {
          font-size: 20px;
        }

        .vision-btn {
          width: 100%;
          // display: flex;
          // justify-content: center;

          button {
            // width: 100%;
            width: 120px !important;
            height: 44px;
          }
        }
      }
    }
  }
}