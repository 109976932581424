.navbar-main {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.07) 100%
  );
  /* backdrop-filter: blur(50px); */
  width: 100%;
  /* height: 100%; */
  /* height: 101px; */
}
.navbar-main .navbar-brand {
  margin-left: 111px;
}
.navbar-main .wallet {
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  color: #000;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}
/* .navbar-nav {
  display: flex;
  justify-content: center;
} */
.mainMiddle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.navUL {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-left: 343px;
}

.navUL .nav-item a {
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-inline: 30px;
}
.walletButton {
  margin-right: 149px;
}
.navbar-toggler {
  background: black;
  border: 1px solid white;
}
.navbar-toggler:hover {
  /* text-decoration: none; */
  outline: white !important;
  /* border: white !important; */
}

@media only screen and (max-width: 1790px) {
  .walletButton {
    margin-right: 100px;
  }
  .navUL .nav-item a {
    margin-inline: 10px;
  }
  .navUL {
    margin-left: 143px;
  }
}

@media only screen and (max-width: 1790px) {
  .navbar-main .navbar-brand {
    margin-left: 10px;
  }
  .walletButton {
    margin-right: 30px;
  }
  .navUL {
    width: 100%;
    margin: 0;
    /* margin-left: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 990px) {
  .navbar-main .navbar-brand {
    margin-left: 10px;
  }
  .mainMiddle {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* justify-content: space-evenly; */
    align-items: flex-start;
    margin-left: 10px;

    /* height: 300px; */
    /* height: 100%; */
  }
  .nav-item {
    /* margin-inline: 30px; */
    padding-inline: 13px;
    margin-block: 10px;
  }
  .walletButton {
    width: 160px;
    height: 54px;
  }
  .wallet {
    width: 100%;
  }
  .nav-item {
    padding-inline: 4px;
  }
}
@media only screen and (max-width: 400px) {
  .navbar-brand .logo {
    width: 140px;
  }
  .navUL .nav-item a {
    margin-inline: 2px;
  }
}
