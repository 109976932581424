/* .bg-img {
  background-image: url("../../assets/icon/Rectangle\ 824.png");
  height: 1px;
}
.shadow {
  background-image: url("../../assets//icon//Ellipse\ 1.png");
  background-position: contain;
  background-repeat: no-repeat;
  width: 100%;
}
.footer-content {
  display: flex;
  width: 100%;
  gap: 20px;
}
.footer-a {
  width: 100%;
  padding-left: 161px;
}
.footer-d {
  width: 100%;
  padding-right: 200px;
}
.footer-b {
  width: 100%;
}
.content-logo {
  display: flex;
  justify-content: center;
}
.content-paragraph {
  display: flex;
  justify-content: center;
}
.footer .copyright {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 111px;
  margin-bottom: 15px;
}
.footer .copyright span {
  color: white;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.content-title {
  padding-top: 156px;
  margin-bottom: 26px;
}
.title-subheading {
  margin-block: 15px;
}
.title-subheading a {
  color: rgba(255, 255, 255, 0.5) !important;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 26px;
}
.title-subheading a:hover {
  color: white !important;
}

.footer-b .title-about {
  color: white;
  text-align: center;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.footer-d .title-about {
  color: white;
  text-align: center;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.footer-a .content-first {
  padding-top: 114px;
}
.social-icons {
  width: 40%;
}
.social-icon {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1110px) {
  .content-logo {
    display: flex;
    justify-content: start;
  }
  .footer-a {
    padding-left: 10px;
  }
  .footer-d {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 810px) {
  .content-title {
    padding-top: 10px;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }
  .content-paragraph {
    display: flex;
    justify-content: start;
  }
} */

.footer {
  display: flex;
  justify-content: space-between;
  padding-left: 160px;
  padding-right: 160px;
  position: relative;
  align-items: baseline;
}
.first-content {
  width: 288px;
}
.footerContent-a {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 26px;
}
.shadow {
  position: absolute;
  /* width: 100%; */
  width: 15%;
  top: 70px;
  left: 100px;
  bottom: 0;
  /* background-color: rgb(93, 59, 14); */
}
.sha {
  width: 100%;
  height: 100%;
}
.p {
  /* position: absolute; */
  color: rgba(255, 255, 255, 0.5);
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 27px */
}
.footerContent-a h6 {
  color: white;
  /* text-align: center; */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.footerContent-a a {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.footerContent-a a:hover {
  color: white;
}
.social-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 5px;
}
.copy-right {
  display: flex;
  justify-content: center;
  margin-top: 111px;
  margin-bottom: 15px;
}
.social-icon .socialIcon {
  color: white;
}

@media screen and (max-width: 1100px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 750px) {
  .footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }
  .social-icons {
    width: 100%;
  }
  .footerContent-a {
    align-items: center;
  }
  .copy-right {
    margin-top: 50px;
  }
}
