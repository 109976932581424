.Movie-section p {
  width: 334px;
  color: #d9d9d9;
  font-family: "Poppins";
  font-size: 61px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 73.2px */
  padding-top: 64px;
  padding-bottom: 42px;
}
.Movie-section {
  /* display: grid; */
  padding-left: 137px;
}

.movie-card-Container {
  display: flex;
  gap: 20px;
  justify-content: start;
  flex-wrap: wrap;
}
.loadmore {
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  color: #000;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}
.load-btn {
  margin-top: 68px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1000px) {
  .Movie-section {
    padding-left: 20px;
  }
}

@media screen and (max-width: 600px) {
  .Movie-section {
    padding-left: 20px;
  }
  .Movie-section p {
    width: 334px;
    color: #d9d9d9;
    font-family: "Poppins";
    margin-top: 10px;
    padding-bottom: 0px;
    padding-top: 10px;
    font-size: 30px;
  }
}
