/* .modalbox-Main {
  padding: 200px;
} */
.modalimg {
  width: 100%;
}
.modal-title {
  display: none;
}
/* .modal-header .btn-close {
  background-color: white !important;
} */
.modal.show .modal-dialog {
  /* padding: 150px; */
  /* padding-inline: 280px;
  padding-block: 100px; */
  border-radius: 7px;
  background: var(
    --card,
    linear-gradient(180deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.07) 100%)
  );
  /* backdrop-filter: blur(50px); */
}

.modal-Content {
  background-color: transparent;
  border-radius: 7px !important;
  border: 1px solid rgba(255, 255, 255, 0.46) !important;
  background: var(
    --card,
    linear-gradient(180deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.07) 100%)
  );
  backdrop-filter: blur(50px);
}
.quizModal-section {
  display: flex;
  width: 100%;
  margin-bottom: 74px;
}
.qmodal-left {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qmodal-right {
  width: 100%;
}
.modal-Content {
  /* border-radius: 7px; */
  background: var(
    --card,
    linear-gradient(180deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.07) 100%)
  );
  backdrop-filter: blur(50px);

  background: rgb(127, 127, 127);
  border: none;
  border-radius: none;
}
.question {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paragraph {
  width: 350px;
}
.question h6 {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 14px;
}
.question p {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalReward {
  margin-top: 54px;
}
.modalReward p {
  color: #fff;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 22px;
}

.modal-content {
  background-color: transparent;
}
.modalReward span {
  color: #ffb800;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.quiz-start {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quiz-start p {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 58px;
}
.quiz-start button {
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}
.start-div {
  width: 331px;
}
.close-button {
  min-height: 28px;
    min-width: 92px;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    border-radius: 10px;
    margin-top: 60px;
}
.loading-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 55vh; */
  margin-top: 6%;
  margin-bottom: 5%;
 
}
/* .loading {
transform: scale(3.5);
} */
/* strock textstrock */
.loading-content h5 {
color: #FFF;
    text-align: center;
    /* font-family: PoetsenOne; */
    font-size: 60px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 20px;  
    text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}
.loading-content p {
font-size: 20px;
text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;

}

.custom-loader {
  width:240px;
  height:44px;
  border-radius: 40px;
  color:white;
  border:4px solid;
  position: relative;
  
}
.custom-loader::before {
  content:"";
  position: absolute;
  margin:4px;
  inset:0 100% 0 0;
  border-radius: inherit;
  background:white;
  animation:p6 4s infinite;
}
@keyframes p6 {
    100% {inset:0}
}
@media only screen and (max-width: 1200px) {
  .quizModal-section {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 600px) {
  .question h6 {
    font-size: 27px;
  }
  .question p {
    font-size: 23px;
  }
  .modalReward p {
    font-size: 23px;
  }
  .modalReward span {
    font-size: 23px;
  }
  .quiz-start p {
    font-size: 18px;
  }
  .quiz-start button {
    padding: 6px 20px;
  }
}
