/* .quiz-banner {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 27px;
}

.quiz-banner .banner-left {
  position: relative;
  width: 25%;
}

.banner-left .left-img {
  margin-left: 70px;
  margin-top: 143px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-logo {
  position: absolute;
  top: -38px;
  left: -28px;
}

.image-content {
  display: block;
  width: 100%;
}
.banner-middle {
  width: 50%;
}
.banner-middle .Middle-img {
  margin-top: 104px;
  text-align: center;
}

.banner-middle .Middle-img p {
  color: white;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 28px;
}
.banner-right {
  width: 25%;
}
.quiz-banner .last-img {
  margin-top: 129px;
} */

.banner-quiz {
  display: flex;
  width: 100%;
  gap: 23px;
  justify-content: space-between;
}
.banner-left {
  width: 25%;
  /* padding-left: 130px; */
  margin-top: 50px;
  margin-left: 30px;
}
.leftimgs {
  width: 100%;
}
.rightimgs {
  width: 100%;
}
.banner-middle {
  width: 100%;
  /* margin-top: 98px; */
}
.banner-right {
  width: 25%;
  margin-top: 50px;
  margin-right: 30px;
}

.rightImg {
  margin-top: 137px;
  width: 100%;
}
.leftImg {
  display: flex;
  justify-content: center;
  /* padding-left: 157px; */
  margin-top: 137px;
  width: 100%;
  position: relative;
  display: inline-block;
}
.middleImg {
  display: flex;
  justify-content: center;
  /* padding-left: 157px; */
  margin-top: 137px;
  width: 100%;
  position: relative;
  display: inline-block;
}
.rightImg {
  display: flex;
  justify-content: center;
  /* padding-left: 157px; */
  margin-top: 137px;
  width: 100%;
  position: relative;
  display: inline-block;
}
.middleImg {
  width: 100%;
}
.middleImgs {
  width: 100%;
}
.image-logo {
  position: absolute;
  top: -38px;
  left: -28px;
}
.middleImg p {
  color: #fff;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 28px;
}

@media only screen and (max-width: 600px) {
  .middleImg {
    margin-top: 50px;
  }
  .leftImg {
    margin-top: 20px;
  }
  .rightImg {
    margin-top: 20px;
  }
  .image-logo {
    top: -8px;
    left: -12px;
    width: 20px;
  }
  .banner-left {
    margin-left: 15px;
  }
  .banner-right {
    margin-right: 15px;
  }
  .middleImg p {
    font-size: 8px;
  }
}
