.quizzes-card {
    background-color: rgba(24, 24, 24, 1), rgba(49, 48, 48, 0.03);
    width: 100%;
    width: 397.712px;
    height: 396.926px;
    padding: 10px 10px 12px 10px;
    border-radius: 7.35px;
    background: linear-gradient(0deg, #181818 0%, rgba(49, 48, 48, 0.03) 92.71%);

    &:hover {
        border-radius: 7.35px;
        border: 1.069px solid #AE00FF;
        background: var(--frame2, linear-gradient(360deg, #FA21B0 1.37%, rgba(3, 3, 3, 0.00) 100%));
        cursor: pointer;
    }

    .card-header {
        width: 100%;
        height: 254.661px;
    }

    .card-body {
        .title {
            color: #FFF;
            font-family: "Montserrat";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .card-footers {
        display: flex;
        justify-content: space-between;
        margin-top: 13px;
        padding: 0 15px;

        .banner-btn {


            button {
                font-size: 14px;

            }
        }


    }

}

@media only screen and (max-width: 1850px) {
    .quizzes-card {
        width: 340px;
    }
}

@media only screen and (max-width: 1200px) {
    .quizzes-card {
        width: 320px;
    }
}

@media only screen and (max-width: 992px) {
    .quizzes-card {
        width: 300px;
    }
}