.banner {
  padding: 154px 31px 96px 153px;
  min-height: 980px;
  position: relative;
  display: flex;
  align-items: center;

  .left {
    .first {
      -webkit-text-stroke: 1px white;
      -webkit-text-fill-color: transparent;



    }


    // h3 {
    //   margin-right: -39px;
    // }

    .engage {
      display: flex;
      justify-content: end;

    }


    .para {
      max-width: 568px;
    }

    max-width: 1089px;
  }


  .right {
    max-width: 695px;
    width: 100%;
    height: 100%;
    max-height: 859px;
    position: absolute;
    right: 0;
    top: 0;

    video {
      mix-blend-mode: screen;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1700px) {
  .banner {
    padding: 40px;
    min-height: 780px;

    h3 {
      font-size: 44px;
    }

    p {
      font-size: 17px;
    }
  }

  .right {
    max-width: 600px;

    max-height: 659px;
  }
}

@media screen and (max-width: 1200px) {
  .banner {
    padding: 30px;

    .right {
      max-width: 400px;
      max-height: 560px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: unset;

    .left {
      width: 100%;

      .para {
        p {
          font-size: 16px;
        }
      }

      h3 {
        font-size: 24px;
      }
    }

    .right {
      margin-top: 10px;
      max-width: 500px;
      position: unset;
    }
  }
}

@media screen and (max-width: 600px) {
  .banner {
    padding: 20px;

    .right {
      width: 350px;
    }

    .left {
      h3 {
        font-size: 18px;
      }

      .para {
        p {
          font-size: 12px;
          max-width: 368px;
        }
      }

      .banner-btn {
        display: flex;
        flex-direction: column;
        gap: 15px;

        Button {
          width: 100%;
        }
      }
    }
  }
}

.text-animation {
  width: fit-content;
  /* Adjust the width as needed */
  overflow: hidden;
  position: relative;
}

.animated-word {
  animation: slide-in-out 4s ease-in-out infinite;
  position: relative;
  margin-left: 25px;
}

@keyframes slide-in-out {

  0%,
  100% {
    transform: translateY(100%);
  }

  25%,
  75% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-100%);
  }
}