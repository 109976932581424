.leaderBoard-title {
  margin-top: 52px;
  margin-bottom: 19px;
}
.leaderBoard-title h4 {
  color: white;
  font-family: "Rugen";
  /* font-size: 40px; */
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 48px */
  text-align: center;
}
.table-title h5 {
  color: white;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 6px;
}
.table-contentmain {
  margin-left: 142px;
  margin-right: 142px;
  overflow-x: auto;
}

.table-body {
  width: 100%;
  border-radius: 7px;
  background: #1a1a1a;
  border: 0.5px solid #fff;
  display: flex;
  border-bottom: none;
}
/* .table-body .tabel-serial {
  width: 51px;
  border-radius: 7px 0px 0px 0px;
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-body .tabel-name {
  width: 30%;
  border: 1px solid white;

  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  padding-top: 30px;
  padding-left: 28px;
  padding-bottom: 29px;
}
.shar-lit {
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.table-body .tabel-progress {
  width: 50%;
  border: 1px solid white;

  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  padding-top: 28px;
  text-align: center;
}
.table-body .tabel-quizzes {
  width: 18%;
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pbar {
  background-color: blue;
  color: red;
}
.progress {
  background: red;
}
.tabel-progress .progressbr {
  height: 25px;
  background: #f3f2f4;
  width: 75%;
  color: #7401aa;
  border-radius: 59px;
  padding: 0;
}
.progress-bar {
  background-color: #7401aa;
  width: 90%;
}
.pr {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
} */
/* new table */
.table {
  border-radius: 7px;
  background: #1a1a1a;
}
.table thead tr th {
  /* width: 51px; */
  /* color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  /* text-align: center;
  border-radius: 7px 0px 0px 0px;  */
  border-bottom: 0.5px solid white;
}
.serial-no {
  width: 51px;
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  text-align: center;
  border-radius: 7px 0px 0px 0px;
  border: 0.5px solid white;
}
.tableName {
  width: 412px;
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  border-radius: 7px 0px 0px 0px;
  border: 0.5px solid white;
}
.quizez {
  width: 153px;
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  text-align: center;
}
.serialNo {
  color: white !important;
  text-align: center;
  border-radius: 7px 0px 0px 0px;
  border-right: 0.5px solid white;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
}
.firstName {
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
}
.secondName {
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}
.progs {
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  border-radius: 7px 0px 0px 0px;
  border: 0.5px solid white;
}

.progressbr {
  height: 25px;
  background: #f3f2f4;
  width: 90%;
  color: #cc72f6;
  border-radius: 59px;
  padding: 0;
}
.progress-bar {
  background: #cc72f6;
  width: 90%;
  border-radius: 15px;
}
.pr {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.pr span {
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
}

.lPercentage {
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
}
.marks {
  color: #fff !important;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  text-align: center;
}
.tabel-progress .progressbr {
  height: 25px;
  background: #f3f2f4;
  width: 50%;
  color: #cc72f6;
  border-radius: 59px;
  padding: 0;
}
.table-structure {
  border-radius: 7px 7px 0px 0px;
  border: 0.5px solid white;
}
.nameborder {
  border-radius: 7px 7px 0px 0px;
  border-right: 0.5px solid white;
}
.table > :not(caption) > * > * {
  border-bottom: none;
}

@media only screen and (max-width: 1600px) {
  .tableName {
    width: 200px;
  }
}
/* Your existing styles... */

@media screen and (max-width: 1400px) {
  .table-contentmain {
    margin-left: 10px;
    margin-right: 10px;
  }
}
/* Existing styles... */

/* Adjust the table width for smaller screens */
@media only screen and (max-width: 768px) {
  .table-structure {
    width: 100%;
  }

  .tableName {
    width: 5%;
  }
  .quizez {
    width: 30px !important;
  }
}

/* Responsive styles for the name column */
@media only screen and (max-width: 610px) {
  .table-title h5 {
    font-size: 20px;
    text-align: center;
  }
  .leaderBoard-title h4 {
    font-size: 25px;
  }
  .serial-no {
    font-size: 12px;
  }
  .tableName {
    font-size: 12px;
  }
  .quizez {
    font-size: 12px;
  }
  .progs {
    font-size: 12px;
  }
  .serialNo {
    font-size: 10px;
  }
  .firstName {
    font-size: 12px;
  }
  .secondName {
    font-size: 12px;
  }
  .marks {
    font-size: 12px;
  }
  .progressbr {
    height: 15px;
  }
  .pr span {
    font-size: 12px;
  }
}

/* Responsive styles for the progress column */
@media only screen and (max-width: 768px) {
  .progressbr {
    width: 100%;
  }
}
