.quizzes-content {
  .quizzes {
    padding: 108px 100px 6px 100px;

    .tabs {
      .nav-tabs {
        border-bottom: none;
        gap: 0 35px;

        .nav-item {
          button {
            color: #b1b1b1;
            font-family: "Montserrat";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            outline: none;
            background: none;
            padding: 0;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -10px;
              width: 0;
              background-color: var(--white);
              height: 1px;
              transition: all 0.3s linear;
            }

            &:hover {
              color: white;

              &::after {
                width: 100%;
              }
            }

            &.active {
              color: var(--white);

              &::after {
                width: 100%;
              }
            }
          }
        }
      }
    }


  }

  // .quizbotom-line {
  //   padding: 0px 188px;
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   margin-bottom: 31px;

  //   .vector-img {
  //     // background-color: red;
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;

  //     img {
  //       width: 100%;
  //     }
  //   }
  // }
}

@media only screen and (max-width:1600px) {
  .quizzes-content {
    .quizzes {
      padding-left: 10px;
      padding-right: 10px;

      .heading {
        h1 {
          font-size: 140px;
        }
      }
    }
  }
}

@media only screen and (max-width:992px) {
  .quizzes-content .quizzes .heading h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width:600px) {


  .quizzes-content .quizzes {
    padding: 20px;

    .tabs {
      .nav-tabs {
        display: flex;
        gap: 0 5px;
        margin-top: 30px;

        .nav-item button {
          font-size: 15px;
        }
      }
    }

    .heading h1 {
      font-size: 30px;
    }
  }





}