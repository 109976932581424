
/* *{
  border: 1px solid yellowgreen;
} */
.quiz-panel {
  width: 100%;
  padding-right: 188px;
}
.quiz-panel .panel-timer {
  display: flex;
  justify-content: end;
  padding-top: 46px;
  padding-bottom: 30px;
}
.quiz-panel .panel-timer p {
  color: white;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.quiz-panel .panel-timer span {
  color: white;
  font-family: "Montserrat";
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.question-panel {
  width: 100%;
  display: flex;
  gap: 100px;
  padding-left: 150px;
  padding-top: 30px;
  /* padding-right: 148px; */
}
.question-panel .question-left {
  /* width: 100%; */
  /* width: 50%; */
}
.question-panel .question-right {
  /* width: 100%; */
  /* width: 749px; */
  /* height: 416px; */
  width: 773px;
}
.question-left h5 {
  color: white;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.left-question p{
  color: white !important;
}
.question-left p {
  color: white;
  text-align: center;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 652px;
  height: 127px;
  margin-top: 16px;
}
.quiz-content {
  width: 654px;
  height: 228px;
  border-radius: 7px;
  background: #121212;
}
.quiz-content {
  height: auto;
  display: flex;
margin-top: 50px;
  /* flex-wrap: nowrap; */
}
.quiz-lefside {
  /* width: 100%;
  margin-left: 66px;
  margin-top: 60px;
  margin-bottom: 58px; */
  /* margin-right: 65px; */
}
.a{
      gap: 10px;
    display: flex;
    flex-direction: column;
}
.quiz-lefside .a {
  /* padding-inline: 30px; */
  margin-block: 20px;
}
.quiz-lefside .a span {
  color: white;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.quiz-lefside .a button {
  gap: 7px;
  padding: 6px;
    width: 85%;
    height: auto;
    border-radius: 35px;
    border: 1px solid white;
    color: white;
    font-family: "Montserrat";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: black;
    margin-left: 26px;
    padding-left: 27px;

}
.quiz-lefside .option {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  padding: 6px;
    width: 85%;
    height: auto;
    border-radius: 35px;
    border: 1px solid white;
    color: white;
    font-family: "Montserrat";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: black;
    margin-left: 26px;
    padding-left: 27px;

}
.icon-container{
 display: flex;
 align-items: center;
 /* margin-left: 10px;  */
}
.icon-green, .icon-red {
  /* width: 20px; 
  height: auto; 
  margin-right: 5px;  */
}
.answer-button {
    padding: 10px 20px;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    color: #000;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
}
.quiz-content .quiz-first {
  /* width: 10%; */
  width: 100%;
  /* height: 100%; */
}
.bg {
  background-color: white;
  width: 100px;
}
/* .question-panel .question-right {
    width: 100%;
  } */
.pen-img {
    width: 100%;
    height: 45vh;
    margin-top: 7%;
    /* width: 60%;
    height: 281px;
    border: 2px solid white !important;
    border-radius: 20px; */
    
}
.quiz-img {
  /* width: 100%; */
  width: 100%;
  height: 100%;
  /* padding-top: 16px; */
}
.vediot {
  width: 100%;
  height: 100%;
}
.quizImg {
  width: 100%;
  height: 100%;
  /* height: %; */
  display: flex;
  justify-content: end;
}

.quizpanel-last {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 150px;
  padding-top: 145px;
  padding-bottom: 103px;
}
.quizpanel-last .quizBottom-left button {
  width: 76px;
  height: 54px;
  border-radius: 7px;
  border: 1px solid white;
  color: #ffb800;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
}
.quizpanel-last button .quizBottom-middle button {
  cursor: not-allowed;
  padding: 10px 20px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}
.next-button:disabled{
  cursor: not-allowed;
}
.quizBottom-right span {
  width: 130px;
  height: 43px;
  color: white;
  text-align: center;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* @media only screen and (max-width: 1700px) {
    .question-panel {
      display: flex;
      gap: 10px;
    }
  } */
/* @media only screen and (max-width: 1400px) {
    .question-panel {
      display: flex;
      flex-direction: column;
    }
    .question-left p {
      width: 100%;
    }
    .quiz-content {
      width: 100%;
      height: 100%;
    }
  
    .quiz-lefside {
      margin-left: 10px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .question-left p {
      text-align: start;
    }
  }
  @media only screen and (max-width: 850px) {
    .quiz-content {
      display: flex;
      flex-direction: column;
    }
    .question-panel {
      margin-left: 10px;
      padding-left: 10px;
    }
    .quizpanel-last {
      padding-left: 0;
    }
    .quiz-panel {
      padding-right: 10px;
    }
  } */

@media only screen and (max-width: 1500px) {
  .question-panel {
    /* padding-left: 20px; */
    gap: 6%;
    padding-left: 80px;
    padding-right: 30px;
  }
  .quiz-panel {
    padding-right: 20px;
  }
  .question-left h5 {
    font-size: 25px;
  }
  .question-left p {
    font-size: 25px;
    width: 100%;
  }
  .quiz-lefside {
    margin-left: 10px;
  }
  .quiz-lefside .a button {
    font-size: 15px;
    width: 400px;
    height: auto;
    margin-bottom: 10px;
  }
  .quiz-lefside .a span {
    font-size: 18px;
    display: flex;
    align-items: baseline;
  }
  .question-panel .question-left {
    width: 500px;
  }
  .quiz-content {
    width: 500px;
  }
  /* .quiz-img {
      width: 500px;
    } */
}
@media only screen and (max-width: 1209px) {
  .question-panel {
    padding-left: 20px;
  }
  .quizpanel-last {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .question-panel {
    display: flex;
    flex-direction: column;
  }
  .question-panel .question-left {
    width: 100%;
  }
  .question-left p {
    text-align: start;
  }
  .question-left p {
    height: 50px;
  }
  .quiz-content {
    width: 100%;
  }
  .question-panel .question-right {
    width: 500px;
  }
}
@media only screen and (max-width: 700px) {
  .question-left {
    order: 2;
  }
  .question-panel .question-right {
    /* width: 300px; */
    width: 300px;
  }
  .quiz-panel .panel-timer p {
    font-size: 17px;
  }
  .quiz-panel .panel-timer span {
    font-size: 30px;
  }
  .question-left p {
    font-size: 15px;
  }
  .quiz-content {
    display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      /* margin-top: 20%; */
  }
  .quiz-lefside {
    margin-top: 0;
    margin-bottom: 0;
  }
  .quiz-lefside .a button {
    font-size: 15px;
    width: 190px;
  }
  .quizpanel-last {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .quizpanel-last .quizBottom-left button {
    width: 51px;
    height: 36px;

    font-size: 22px;
  }
  .quizpanel-last .quizBottom-middle button {
    font-size: 15px;
  }
  .quizBottom-right span {
    font-size: 23px;
  }
  .quizBottom-left .a span {
    font-size: 15px;
  }
}

@media only screen and (max-width:350px) {
    .quiz-content {
      margin-top: 20%;
  }
}
