.text-black {
  color: var(--black);
}

.text-white {
  color: var(--white);
}

.text-yellow {
  color: var(--yellow);
}

h1 {
  font-family: "Rugen";
  font-size: 198.463px;
  font-style: normal;
  font-weight: 400;
  // line-height: 120%;
}

h2 {
  font-family: "Rugen";
  font-size: 150px;
  font-style: normal;
  font-weight: 400;
  // line-height: 120%;
}

h3.montserrat {
  font-family: "Montserrat";
}

h3 {
  font-family: "Rugen";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

h4 {
  font-family: "Montserrat";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

h5 {
  color: white;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

h6 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p {
  color: #FFF;
  font-family: "Montserrat";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  /* 36px */
}

// font weight
.fw-500 {
  font-weight: 500;
}

// .border-b {
//   border-bottom: 1px solid #ef00f4;


// }