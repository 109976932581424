* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // outline: 1px solid yellow;
}

body {
  font-family: "Montserrat", sans-serif, serif;
  color: var(--white);
  background-color: var(--black);
  // max-width: 1920px;
  margin: 0px auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
ul,
li,
.row {
  margin-bottom: 0;
  padding-bottom: 0;
}

.text-decoration {
  text-decoration: none;
}

.mb {
  margin-bottom: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

// flex alignments

.v-center {
  display: flex;
  align-items: center;
}

.h-center {
  display: flex;
  justify-content: center;
}

.v-between {
  display: flex;
  justify-content: space-between;
}

.c-direction {
  display: flex;
  flex-direction: column;
}

.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.anchor {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

// ----------margins

.mt-60 {
  margin-top: 60px;
}

.img-100 {
  width: 100%;
  height: 100%;
  // object-fit: cover;
}