.featuresParent {
  position: relative;
  z-index: -5;
  margin-top: -80px;
}

.feature {

  .heading {
    h2 {
      text-align: center;

      span {
        -webkit-text-stroke: 1px white;
        -webkit-text-fill-color: transparent;
        position: relative;
        font-family: "Rugen";
        font-size: 150px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        width: 100%;
        height: 100%;

        .shadow-div {
          position: absolute;
          z-index: 2;
          background: linear-gradient(transparent, black);
          width: 100%;
          height: 100%;
          // margin-inline: 130px;
          margin-block: 8px;
          left: 0;
          bottom: 0;
        }

      }


    }


  }

  margin-top: 67px;

  .feature-content {
    padding: 146px 220px 0px 220px;


    .content-1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 100px;

      .left {
        width: 50%;
        // border-left: 5px solid red;
        padding-left: 11px;
        position: relative;

        .border-left {

          /* width: 100%; */
          /* height: 144px; */
          position: absolute;
          left: -10px;
          top: 0;
          background: linear-gradient(180deg, rgba(185, 54, 245, 0.97) 0%, rgba(0, 0, 0, 0.05) 133.68%);
          width: 5px;
          height: 100%;

        }

        h4 {
          color: white;
          font-family: "Montserrat";
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        }

        p {
          color: white;
          font-family: "Montserrat";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 180%;
        }

      }

      .right {
        width: 50%;


        video {
          width: 100% !important;
        }
      }
    }

    .content-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 100px;

      .left {
        width: 50%;

        video {
          width: 100%;
        }
      }

      .right {
        width: 50%;
        // border-left: 5px solid red;
        padding-left: 11px;
        position: relative;

        .border-left {
          /* width: 100%; */
          /* height: 144px; */
          position: absolute;
          left: -10px;
          top: 0;
          background: linear-gradient(180deg, rgba(185, 54, 245, 0.97) 0%, rgba(0, 0, 0, 0.05) 133.68%);
          width: 5px;
          height: 100%;
        }


        h4 {
          color: white;
          font-family: "Montserrat";
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        }

        p {
          color: white;
          font-family: "Montserrat";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 180%;
        }
      }
    }
  }




}

.border-b {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #EF00F4 47.4%, rgba(217, 217, 217, 0) 100%, rgba(0, 0, 0, 0) 100%);


  // img {
  //   width: 100% !important;
  // }
}

@media screen and (max-width:1850px) {
  .feature {
    .heading {
      h2 {
        font-size: 90px;

        span {
          font-size: 90px;

          .shadow-div {
            width: 100%;
          }
        }
      }
    }

    .feature-content {
      padding: 100px 100px 0px 100px !important;

      .content-1 {
        .left {
          h4 {
            font-size: 38px;
          }
        }
      }

      .content-2 {
        .right {
          h4 {
            font-size: 38px;
          }
        }
      }



    }
  }
}

@media screen and (max-width:1350px) {
  .feature {
    .heading {

      h2 {
        font-size: 60px;

        span {
          font-size: 60px;

          .shadow-div {
            display: none;
          }
        }
      }
    }

    .feature-content {
      padding: 70px 70px 0px 70px;

      .content-1 {
        gap: 20px;

        .left {
          h4 {
            font-size: 20px;
          }

          p {
            font-size: 15px;
          }
        }
      }

      .content-2 {
        gap: 20px;

        .right {
          h4 {
            font-size: 20px;
          }

          p {
            font-size: 15px;
          }
        }
      }

    }
  }
}

@media screen and (max-width:800px) {
  .feature {
    .heading h2 {
      font-size: 20px;

      span {
        font-size: 20px;

        .shadow-div {
          width: 100%;
        }
      }
    }

    .feature-content {
      padding: 20px !important;

      .content-1 {
        display: flex;
        flex-direction: column;

        .left {
          width: 100%;
        }

        .right {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: center;

          video {
            max-width: 400px;
          }
        }




      }

      .content-2 {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        align-items: start;



        .right {
          width: 100%;
          border-left: none;

          h4 {
            font-size: 16px;
          }

          p {
            font-size: 14px;
          }
        }

        .left {
          width: 100%;

          margin-top: 50px;
          display: flex;
          order: 1;
          justify-content: center;

          video {
            max-width: 400px;
            width: 100%;
          }

        }

      }

      .content-3 {
        display: flex;
        flex-direction: column;

        .right {
          margin-top: 50px;
        }

        .left {
          margin-right: 0;
          border-left: none;

          h4 {
            font-size: 16px;
          }

          p {
            font-size: 14px;
          }
        }
      }

      .content-4 {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 50px;

        .right {
          border-left: none;

          h4 {
            font-size: 16px;
          }

          p {
            font-size: 14px;
          }
        }

        .left {
          margin-right: 0;
          margin-top: 50px;
        }
      }
    }
  }


}