.btn-primary {
  background: #fff;
  color: #000;
}
.btn-secondary {
  background: var(--pink);
  color: var(--white);
}
.btn-prime {
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  border-radius: 10px;
}
