.wallet-section .wallet-topbar {
  /* width: 100%; */
  margin-top: 32px;
  margin-left: 161px;
  margin-right: 168px !important;
  border-radius: 7px;
  background: #161616;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
}
.wallet-topbar .wallet-title {
  margin-left: 22px;
  padding-top: 23px;
  /* margin-top: 23px; */
}
.wallet-topbar .wallet-title h5 {
  color: white;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subheading {
  margin-top: 57px;
  margin-left: 22px;
}
.subheading p {
  color: white;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.balance-percentage {
  display: flex;
  align-items: center;
  width: 100%;
}
.balance-percentage .percentage-left {
  margin-left: 22px;
}
.balance-percentage .percentage-right {
  width: 100%;
  margin-left: 3px;
  margin-right: 38px;
}
.balance-percentage .percentage-right span {
  color: white;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.percentage-right .progressbr {
  height: 8px;
  background: white;
  /* width: 35%; */
  color: #7401aa;
  /* color: #7401aa !important; */
}
.progress-bar {
  background-color: #7401aa;
}
.topbar-bottom .title p {
  color: white;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 11px;
  margin-bottom: 23px;
  margin-left: 22px;
}

.topbar-bottom .title button {
  width: 892px;
  height: 41px;
  border-radius: 7px;
  background: white;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.53);
  color: #585757;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  margin-left: 22px;
  margin-bottom: 23px;
}
.walletlast-content {
  /* width: 100%; */
  margin-left: 161px;
  margin-right: 430px;
  margin-bottom: 78px;
}
.status-title {
  display: flex;
  justify-content: end;
  /* width: 100%; */
  margin-left: 161px;
  margin-top: 60px;
  margin-bottom: 43px;
  /* margin-right: 168px; */
}
.status-title button {
  width: 181px;
  height: 36px;
  border-radius: 7px;
  background: rgba(209, 12, 12, 0.36);
  color: white;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}
.payment-detail {
  margin-left: 280px;
  margin-bottom: 31px;
}
.payment-detail .balencelabel {
  width: 100%;
  color: white;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 34px;
}
.payment-detail .balencefield {
  width: 100%;
  height: 49px;
  border-radius: 7px;
  background: white;
}
.payment-detail .balencefielddropd {
  width: 100%;
  height: 49px;
  border-radius: 7px;
  /* background: white; */
}
.payment-detail .balencefielddropd::placeholder {
  padding-left: 20px;
  color: #898888;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.payment-detail .balencefield::placeholder {
  padding-left: 20px;
  color: #898888;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-select {
  padding-left: 20px;
  color: #898888;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-select::placeholder {
  color: #898888;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.payment-detail button {
  width: 100%;
  height: 49px;
  border-radius: 7px;
  background: white;
  box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.25);
  color: #000;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 11px;

  /* display: flex;
  justify-content: center;
  align-items: center; */
  border: none;
}
.payment-detail .cash-out {
  height: 49px;
  border-radius: 7px;
  border: 1px solid white;
  box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.25);
  color: var(--s, #fff);
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: black;
  color: white;
}

@media only screen and (max-width: 1260px) {
  .topbar-bottom .title button {
    width: 50%;
  }
  .wallet-section .wallet-topbar {
    margin-left: 110px !important;
  }
  .payment-detail {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 973px) {
  .wallet-section .wallet-topbar {
    margin-left: 0 !important;
  }
  .walletlast-content {
    margin-left: 10px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 653px) {
  .wallet-section .wallet-topbar {
    margin-right: 0 !important;
  }
  .subheading p {
    margin-bottom: 20px;
  }
  .balance-percentage {
    margin-bottom: 20px;
  }
}
