.quiz-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;





}

.button-show {
  display: flex;
  justify-content: center;
  margin-top: 58px;
  // margin-bottom: -85px;

  .showmore-btn {
    padding: 10px 20px !important;
    border-radius: 10px;
    background: #FFF;
    color: #000;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
  }

}

@media screen and (max-width: 1500px) {
  .quiz-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: 992px) {
  .quiz-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  .quiz-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}