.quizbotom-line {
  padding: 0px 188px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 31px;
}

.quizbotom-line .vector-img {
  /* background-color: red; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.quizbotom-line .vector-img img {
  width: 100%;
}
