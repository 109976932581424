.accordian-content {
  margin-top: 100px;
}
.accordian-content .accordian-title {
  text-align: center;
  margin-bottom: 46px;
}
.accordian-content .accordian-title span {
  color: white;
  text-align: center;
  font-family: "Montserrat";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%; /* 72px */
}
.accordian-content .accordian-title .question {
  color: white;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.accordianMain {
  display: flex;
  width: 100%;
  gap: 30px;
  margin-bottom: 137px;
}

.accordianMain .accordian-left {
  width: 50%;
  padding-left: 323px;
}
.accordianMain .accordian-right {
  width: 50%;
  padding-right: 323px;
}
.accordion-button::after {
  display: none;
}
.custom-button {
  background-color: black;
  color: white;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  box-shadow: none !important;
  padding-left: 0;
  padding-right: 0;
  /* border-bottom: 1px solid white; */
}
/* .custom-button:hover {
  background-color: black;
  color: white;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: none;
  border: none;
} */
.accordion-item {
  border: none;
  background-color: black;
  color: white;
  border-bottom: 1px solid white;
}
.accordion-item h2 {
  /* border-bottom: 1px solid white; */
  background-color: black;
  color: white;
  /* border: none;
  box-shadow: none; */
}
.accordion-button:not(.collapsed) {
  background-color: black;
  color: white;
  box-shadow: none;
  border: none;
}
.accordion-item:first-of-type .accordion-button {
  border: none;
}
.accordion-item:first-of-type .accordion-button:hover {
  border: none;
}
/* .accordion-body {
} */
/* .accordian-content .newsLetter {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
} */
/* .newsLetter-content {
  width: 1000px;
} */
/* .newsletter-title {
  color: white;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 78px;
}
.newsLetter-email {
  width: 507px;
  height: 53px;

  border-radius: 36px;
  border: 0px solid white;
  background: white;
}
.newsLetter-email::placeholder {
  color: #909090;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;
} */
.newsLetter {
  display: flex;
  justify-content: center;
}
.newsLetter-content {
  display: flex;
  /* align-items: center; */
  margin-bottom: 128px;
  gap: 20px;
}
.newsletter-title {
  /* margin-right: 30px; */
  color: white;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.input-container input {
  padding-right: 70px;
}

.newsLetter-email {
  padding: 8px 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 507px;
  height: 53px;
  border-radius: 36px;
  border: 0px solid white;
  background: white;
}
.accordion-button:not(.collapsed) {
  color: none;
  box-shadow: none;
}

.send-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 1600px) {
  .accordianMain .accordian-left {
    padding-left: 20px;
  }
  .accordianMain .accordian-right {
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  /* .accordianMain .accordian-left {
    padding-left: 50px;
  }
  .accordianMain .accordian-right {
    padding-right: 50px;
  } */
  .accordianMain {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
  }
  .accordianMain .accordian-left {
    width: 100%;
    padding: 0;
    /* padding-right: 10px; */
  }
  .accordianMain .accordian-right {
    width: 100%;
    padding: 0;
    /* padding-left: 10px; */
  }
}

@media only screen and (max-width: 650px) {
  .accordian-content .accordian-title {
    text-align: center;
    margin-bottom: 20px;
  }
  .question {
    padding-top: 20px !important;
  }
  .newsLetter {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
  }
  .newsLetter-content {
    display: flex;
    flex-direction: column;
  }
  .newsLetter-email {
    width: 100%;
  }

  .newsLetter-content {
    width: 100%;
    display: flex;
  }
}
